<template>
  <div>
    <security />
  </div>
</template>
<script>
import Security from "./SettingTabs/Security.vue";
export default {
  components: {
    Security,
  },
};
</script>
