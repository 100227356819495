var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"title":"Change Password"}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var pristine = ref.pristine;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSaveChanges.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{ref:"password",attrs:{"name":"password","vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","state":errors.length > 0 ? false : null,"type":_vm.passwordFieldType,"name":"password","placeholder":"Current Password"},model:{value:(_vm.passwordEdit.password),callback:function ($$v) {_vm.$set(_vm.passwordEdit, "password", $$v)},expression:"passwordEdit.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon('password')},on:{"click":function($event){return _vm.togglePasswordVisibility('password')}}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{ref:"new_password",attrs:{"name":"new_password","vid":"new_password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"new-password","state":errors.length > 0 ? false : null,"type":_vm.newPasswordFieldType,"name":"new-password","placeholder":"New Password"},model:{value:(_vm.passwordEdit.newPassword),callback:function ($$v) {_vm.$set(_vm.passwordEdit, "newPassword", $$v)},expression:"passwordEdit.newPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon('newPassword')},on:{"click":function($event){return _vm.togglePasswordVisibility('newPassword')}}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{ref:"confirmPassword",attrs:{"name":"ConfirmPassword","vid":"confirmPassword","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"confirm-password","state":errors.length > 0 ? false : null,"type":_vm.confirmPasswordFieldType,"name":"confirm-password","placeholder":"Confirm New Password"},model:{value:(_vm.passwordEdit.confirmPassword),callback:function ($$v) {_vm.$set(_vm.passwordEdit, "confirmPassword", $$v)},expression:"passwordEdit.confirmPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon('confirmPassword')},on:{"click":function($event){return _vm.togglePasswordVisibility('confirmPassword')}}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-card-text',{staticClass:"mt-2"},[_c('p',{staticClass:"font-weight-bold"},[_vm._v(" Password Requirements: ")]),_c('ul',[_c('li',[_vm._v("Minimum 8 characters long - the more, the better")]),_c('li',[_vm._v("At least one lowercase character")]),_c('li',[_vm._v("At least one number, symbol, or whitespace character")])])]),_c('div',{staticClass:"mt-2"},[_c('b-button',{staticClass:"mr-2",attrs:{"type":"submit","variant":"primary","disabled":pristine || invalid || _vm.isLoading}},[_vm._v(" SAVE CHANGES ")]),_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.onReset()}}},[_vm._v(" RESET ")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }