<template>
  <div>
    <b-card title="Change Password">
      <validation-observer
        ref="observer"
        #default="{ invalid, pristine }"
      >
        <b-form @submit.prevent="onSaveChanges">
          <b-row>
            <b-col sm="6">
              <validation-provider
                #default="{ errors }"
                ref="password"
                name="password"
                vid="password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="password"
                    v-model="passwordEdit.password"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="password"
                    placeholder="Current Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon('password')"
                      @click="togglePasswordVisibility('password')"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col sm="6">
              <validation-provider
                #default="{ errors }"
                ref="new_password"
                name="new_password"
                vid="new_password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="new-password"
                    v-model="passwordEdit.newPassword"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    :type="newPasswordFieldType"
                    name="new-password"
                    placeholder="New Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon('newPassword')"
                      @click="togglePasswordVisibility('newPassword')"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <validation-provider
                #default="{ errors }"
                ref="confirmPassword"
                name="ConfirmPassword"
                vid="confirmPassword"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="confirm-password"
                    v-model="passwordEdit.confirmPassword"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    :type="confirmPasswordFieldType"
                    name="confirm-password"
                    placeholder="Confirm New Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon('confirmPassword')"
                      @click="togglePasswordVisibility('confirmPassword')"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>

          <b-card-text class="mt-2">
            <p class="font-weight-bold">
              Password Requirements:
            </p>
            <ul>
              <li>Minimum 8 characters long - the more, the better</li>
              <li>At least one lowercase character</li>
              <li>At least one number, symbol, or whitespace character</li>
            </ul>
          </b-card-text>

          <div class="mt-2">
            <b-button
              type="submit"
              variant="primary"
              class="mr-2"
              :disabled="pristine || invalid || isLoading"
            >
              SAVE CHANGES
            </b-button>
            <b-button
              variant="outline-secondary"
              @click="onReset()"
            >
              RESET
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BForm,
  BInputGroup,
  BInputGroupAppend,
  BCardText,
  BButton,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import authService from "@/services/authService";
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";


const passwordFormDefault = {
  password: "",
  newPassword: "",
  confirmPassword: "",
};

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BForm,
    ValidationObserver,
    ValidationProvider,
    BInputGroup,
    BInputGroupAppend,
    BCardText,
    BButton,
  },

  data() {
    return {
      passwordEdit: {},
      passwordFieldType: "password",
      newPasswordFieldType: "password",
      confirmPasswordFieldType: "password",
      isLoading: false
    };
  },

  methods: {
    passwordToggleIcon(type) {
      if (type === "password") {
        return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
      } else if (type === "newPassword") {
        return this.newPasswordFieldType === "password"
          ? "EyeIcon"
          : "EyeOffIcon";
      } else {
        return this.confirmPasswordFieldType === "password"
          ? "EyeIcon"
          : "EyeOffIcon";
      }
    },
    togglePasswordVisibility(type) {
      if (type === "password") {
        this.passwordFieldType =
          this.passwordFieldType === "password" ? "text" : "password";
      } else if (type === "newPassword") {
        this.newPasswordFieldType =
          this.newPasswordFieldType === "password" ? "text" : "password";
      } else {
        this.confirmPasswordFieldType =
          this.confirmPasswordFieldType === "password" ? "text" : "password";
      }
    },
    deepClone(data) {
      return JSON.parse(JSON.stringify(data));
    },
    onReset() {
      this.passwordEdit = this.deepClone(passwordFormDefault);
    },
    async onSaveChanges() {
      try {
        this.isLoading = true;
        const response = await authService.changePassword({
          password: this.passwordEdit.password,
          new_password: this.passwordEdit.newPassword,
          new_password_confirmation: this.passwordEdit.confirmPassword,
        });
        if (response) {
          this.$toast(makeSuccessToast("Your password was updated successfully!"));
        }
      } catch (e) {
        const { status, data } = e.response;
        //server may respond with vaidation errors
        if (status === 422 && data.errors) {
          this.$refs.observer.setErrors(data.errors);
        } else {
          this.$toast(makeErrorToast("Something went wrong"));
          this.$log.error(e);
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
